exports.components = {
  "component---src-components-product-category-template-tsx": () => import("./../../../src/components/ProductCategoryTemplate.tsx" /* webpackChunkName: "component---src-components-product-category-template-tsx" */),
  "component---src-components-product-template-tsx": () => import("./../../../src/components/ProductTemplate.tsx" /* webpackChunkName: "component---src-components-product-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dostawa-i-platnosci-tsx": () => import("./../../../src/pages/dostawa-i-platnosci.tsx" /* webpackChunkName: "component---src-pages-dostawa-i-platnosci-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kasa-tsx": () => import("./../../../src/pages/kasa.tsx" /* webpackChunkName: "component---src-pages-kasa-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-newsletter-anuluj-tsx": () => import("./../../../src/pages/newsletter-anuluj.tsx" /* webpackChunkName: "component---src-pages-newsletter-anuluj-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-pielegnacja-tsx": () => import("./../../../src/pages/pielegnacja.tsx" /* webpackChunkName: "component---src-pages-pielegnacja-tsx" */),
  "component---src-pages-regulamin-tsx": () => import("./../../../src/pages/regulamin.tsx" /* webpackChunkName: "component---src-pages-regulamin-tsx" */),
  "component---src-pages-reklamacje-i-zwroty-tsx": () => import("./../../../src/pages/reklamacje-i-zwroty.tsx" /* webpackChunkName: "component---src-pages-reklamacje-i-zwroty-tsx" */),
  "component---src-pages-sukces-tsx": () => import("./../../../src/pages/sukces.tsx" /* webpackChunkName: "component---src-pages-sukces-tsx" */),
  "component---src-pages-zamowienia-indywidualne-tsx": () => import("./../../../src/pages/zamowienia-indywidualne.tsx" /* webpackChunkName: "component---src-pages-zamowienia-indywidualne-tsx" */)
}

