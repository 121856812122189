import React from 'react'
import { Button } from '../ui/Button'
import { useGlobalStore } from '../store/global'
import { useIsMobileView } from '../hooks/isMobile.hook'

export const CookiesBanner: React.FC = () => {
	const store = useGlobalStore()
	const [isViewExtended, setIsViewExtended] = React.useState(false)
	const isMobile = useIsMobileView()
	if (store.shouldShowCookiesBanner === false) return null
	return (
		<div
			className={`fixed bottom-0 left-0 right-0 z-10 m-4 flex items-center gap-4 overflow-auto bg-[#20252A] px-8 py-6 text-xs text-white ${
				isViewExtended && isMobile ? 'max-h-[140px] flex-col' : 'flex-row'
			}`}
		>
			<span className="text-2xl">🍪</span>
			<p>
				{isMobile && !isViewExtended ? (
					<>
						Informujemy, iż w celu realizacji usług dostępnych w naszym
						sklepie...
						<span
							className="ml-1 text-burnt-orange underline"
							onClick={() => setIsViewExtended(true)}
						>
							rozwiń
						</span>
					</>
				) : (
					<>
						Informujemy, iż w celu realizacji usług dostępnych w naszym sklepie,
						optymalizacji jego treści oraz dostosowania sklepu do Państwa
						indywidualnych potrzeb korzystamy z informacji zapisanych za pomocą
						plików cookies na urządzeniach końcowych użytkowników. Pliki cookies
						można kontrolować za pomocą ustawień swojej przeglądarki
						internetowej. Dalsze korzystanie z naszego sklepu internetowego, bez
						zmiany ustawień przeglądarki internetowej oznacza, iż użytkownik
						akceptuje stosowanie plików cookies. Więcej informacji zawartych
						jest w{' '}
						<a
							aria-label="polityka prywatnosci"
							className="inline text-turquoise-dark hover:underline"
							href="https://nefee.b-cdn.net/Polityka%20prywatnos%CC%81ci%20(RODO).doc.pdf"
							target="_blank"
							rel="noreferrer"
						>
							polityce prywatności sklepu
						</a>{' '}
						.
					</>
				)}
			</p>
			<Button
				text="Rozumiem"
				className="text-sm"
				onClick={() => store.setShouldShowCookiesBanner(false)}
			/>
			<Button
				text="Odrzucam"
				className="text-sm"
				onClick={() => store.setShouldShowCookiesBanner(false)}
			/>
		</div>
	)
}
