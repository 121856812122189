export const notifyAboutProductAvailability = async (
	productId: number,
	email: string
) => {
	const response = await fetch(
		`${process.env.GATSBY_API_URL}/products/${productId}/wishlist`,
		{
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({
				email,
			}),
		}
	)
	return response
}

export const createNewsletterSubscription = async (email: string) => {
	const response = await fetch(`${process.env.GATSBY_API_URL}/newsletter`, {
		method: 'POST',
		body: JSON.stringify({
			email,
		}),
		headers: { 'Content-Type': 'application/json' },
	})
	return response
}

export const deleteNewsletterSubscription = async (cancelCode: string) => {
	const response = await fetch(
		`${process.env.GATSBY_API_URL}/newsletter/${cancelCode}`,
		{
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json' },
		}
	)
	return response
}
